import { Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import AnalyticsCard from "../components/ui/AnalyticsCard";
import {
  Add,
  BlockOutlined,
  CalendarMonth,
  Delete,
  Mail,
} from "@mui/icons-material";
import TableDataLoader from "../components/dataGrid/TableDataLoader";
import { toApiUrl } from "../utils/generic";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import Title from "../components/ui/Title";
import withAuth from "../hoc/withAuth";
import ActionsMenu from "../components/dataGrid/ActionsMenu";
import useDataSlice from "../components/dataGrid/useDataSlice";
import axios from "../apiClient";
import { addNotification } from "../store/actions/notifications";
import useForm from "../store/hooks/useForm";
import ValidationService from "easy-validation-service";
import Input from "../components/forms/Input";

const BlacklistColumns = [
  {
    name: "id",
    label: "ID",
  },

  { name: "email", label: "Email" },
  {
    name: "created_at",
    label: "Created",
    renderComponent: ({ value }) => new Date(value).toLocaleString("el-GR"),
  },
  {
    name: "actions",
    label: "Actions",
    renderComponent: ActionsMenu,
  },
];

function BlacklistPage() {
  const [selectedRow, setSelectedRow] = useState(null);
  const [dataSlice, getData, setLoading] = useDataSlice("blacklist");

  const formConfig = useForm({
    open: { defaultValue: false },
    email: {
      defaultValue: "",
      validators: (v) => ValidationService.validateEmail(v),
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <AnalyticsCard
          title="Blacklist"
          icon={<BlockOutlined />}
        ></AnalyticsCard>
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        style={{ maxWidth: "100%", overflowX: "auto" }}
      >
        <TableDataLoader
          identifier="blacklist"
          entityName="results"
          endpoint={toApiUrl("/blacklist")}
          columns={BlacklistColumns}
          renderToolbarChildren={() => (
            <IconButton
              onClick={() => {
                formConfig.resetForm({ email: "", open: true });
              }}
            >
              <Add />
            </IconButton>
          )}
          actions={[
            {
              type: "Delete",
              title: "Delete",
              action: async (row) => {
                try {
                  setLoading(true);
                  await axios.delete(toApiUrl(`/blacklist/${row.id}`));
                  await getData(
                    dataSlice?.page,
                    dataSlice?.view,
                    dataSlice?.filters
                  );
                  addNotification("Blocked entry deleted", "success");
                  return true;
                } catch (error) {
                  addNotification("Blocked entry was not deleted", "error");
                } finally {
                  setLoading(false);
                }
              },
              icon: <Delete />,
            },
          ]}
        />
      </Grid>
      <SimpleDialog
        open={!!formConfig.form.open.value}
        onConfirm={async () => {
          const { email } = formConfig.getFormValues();
          try {
            setLoading(true);
            await axios.post(toApiUrl(`/blacklist`), { email });
            await getData(dataSlice?.page, dataSlice?.view, dataSlice?.filters);
            addNotification("Blocked entry created", "success");
            formConfig.resetForm({ open: false, email: "" });
            return true;
          } catch (error) {
            addNotification("Blocked entry was not created", "error");
          } finally {
            setLoading(false);
          }
        }}
        onCancel={() => formConfig.resetForm({ open: false, email: "" })}
        disableConfirm={!formConfig.isValid}
      >
        <Input
          {...formConfig.form.email}
          label="Email"
          value={formConfig.form.email.value}
          name="email"
          onChange={formConfig.handleChange}
        />
      </SimpleDialog>
      <SimpleDialog open={!!selectedRow} onCancel={() => setSelectedRow(null)}>
        <Grid container spacing={2}>
          <Grid item>
            <Title>
              <Mail sx={{ mr: 2 }} /> {selectedRow?.email}
            </Title>
          </Grid>
          <Grid item>
            <Title>
              <CalendarMonth />
              {new Date(selectedRow?.created_at)?.toLocaleString("el-GR")}
            </Title>
          </Grid>
        </Grid>
      </SimpleDialog>
    </Grid>
  );
}
export default withAuth(BlacklistPage);
